<template>
  <div class="create-event">
    <h2 class="mb-3 text-black">Create Event</h2>
    <InputText
      v-model="form.eventName"
      textFloat="Event name"
      placeholder="Event name"
      type="text"
      name="text"
      isRequired
      :isValidate="$v.form.eventName.$error"
      :v="$v.form.eventName"
    />
    <h4 class="text-black mb-3">Schedule</h4>
    <div class="d-flex">
      <div class="input-flex">
        <div class="font-weight-bold mb-2 text-black">From Date / Time</div>
        <div class="input-container">
          <datetime
            v-model="form.startReserveDate"
            format="dd/MM/yyyy"
            value-zone="local"
            placeholder="Please Select Date"
            ref="startDate"
            :min-datetime="minDate"
          >
          </datetime>
          <div
            class="icon-primary text-right"
            @click="$refs.startDate.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              class="pointer g-text-gold"
              color="#B41BB4"
            />
          </div>
        </div>
      </div>
      <div class="mx-1"></div>
      <div class="input-flex">
        <div class="font-weight-bold mb-2 text-black">To Date / Time</div>
        <div class="input-container">
          <datetime
            v-model="form.endReserveDate"
            format="dd/MM/yyyy"
            value-zone="local"
            placeholder="Please Select Date"
            ref="endDate"
            :min-datetime="minDateEndtime"
            :disabled="!form.startReserveDate"
          >
          </datetime>
          <div
            class="icon-primary text-right"
            @click="$refs.endDate.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              class="pointer g-text-gold"
              color="#B41BB4"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="input-flex">
        <InputText
          v-model="form.dutation"
          textFloat="Dutation (Minute)"
          placeholder="0"
          type="number"
          name="number"
          isRequired
          :isValidate="$v.form.dutation.$error"
          :v="$v.form.dutation"
        />
      </div>
      <div class="mx-1"></div>
      <div class="input-flex">
        <InputText
          v-model="form.bufferTimeAfter"
          textFloat="Buffer time after (Minute)"
          placeholder="0"
          type="number"
          name="number"
          isRequired
          :isValidate="$v.form.bufferTimeAfter.$error"
          :v="$v.form.bufferTimeAfter"
        />
      </div>
    </div>
    <div class="footer-save" @click="onInsertEvent">
      <b-button class="btn-save">
        Save
        <b-spinner
          class="m-0 ml-1"
          label="Spinning"
          small
          v-if="isLoading"
        ></b-spinner>
      </b-button>
    </div>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        eventName: "",
        startReserveDate: "",
        endReserveDate: "",
        dutation: null,
        bufferTimeAfter: null,
      },

      // state date
      minDate: null,
      minDateEndtime: null,

      // state loading
      isLoading: false,
    };
  },
  validations() {
    return {
      form: {
        eventName: { required },
        dutation: { required, minValue: minValue(1) },
        bufferTimeAfter: { required },
      },
    };
  },
  watch: {
    "form.startReserveDate"(val) {
      this.minDateEndtime = new Date(val).toISOString();

      this.form.endReserveDate = null;
    },
  },
  mounted() {
    this.minDate = new Date().toISOString();
  },
  methods: {
    async onInsertEvent() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      this.isLoading = true;

      if (!this.form.startReserveDate) {
        this.form.startReserveDate = null;
      }

      if (!this.form.endReserveDate) {
        this.form.endReserveDate = null;
      }

      const insert = await this.$services.createevent.insertEvent(this.form);

      if (insert.result == 1) {
        this.isLoading = false;

        this.$router.push(`event/details/${insert.detail.eventId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-event {
  .text-black {
    color: black;
  }

  .text-error {
    margin: 0;
  }

  .border-red {
    border-color: red;
  }

  background: white;
  padding: 15px;
  min-height: 78vh;

  position: relative;

  .input-flex {
    width: 100%;
  }

  .footer-save {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .btn-save {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--secondary-color);
    }
  }
}
</style>
